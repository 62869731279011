import React, { useEffect } from "react";
import { DefaultPageInterface } from "./types";
import * as SC from "./styles";
import { Footer, Header, ContactsWidget } from "layouts/outer/components";
import { AppTheme } from "../../AppTheme";
const DefaultPage: React.FC<DefaultPageInterface> = ({
  hasBackground = true,
  children,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AppTheme>
      <SC.Page $hasBackground={hasBackground}>
        <Header />
        <SC.PageContent>{children}</SC.PageContent>
        <Footer />
        <ContactsWidget />
      </SC.Page>
    </AppTheme>
  );
};

export default DefaultPage;
