import styled from "styled-components";

export const Button = styled.button`
  position: absolute;
  padding: 0;
  padding-left: 30px;
  top: 35px;
  right: 55px;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.fontSecondaryColor};
  font-family: ${(props) => props.theme.fonts.regular};
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 25px;
  }

  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    > * {
      fill: ${(props) => props.theme.colors.fontSecondaryColor};
      transition: all 0.2s ease;
    }
  }

  &:hover {
    color: ${(props) => props.theme.colors.primeColor};

    svg {
      > * {
        fill: ${(props) => props.theme.colors.primeColor};
      }
    }
  }
`;
