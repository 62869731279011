import React from "react";
import * as SC from "./styles";
import { AppTitle } from "../../../AppTitle";
import { Link } from "react-router-dom";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";

const Card = ({ item }) => {
  const linkPath = `/tournament/${item.id}`;
  return (
    <SC.Card>
      <SC.Image as={Link} to={linkPath}>
        <img src={`${item.host}${item.image}`} alt="" />
      </SC.Image>
      <SC.CardContent>
        <SC.Date>
          {item.date_from_parsed} <span></span> {item.date_to_parsed}
        </SC.Date>
        <AppTitle lvl={3} size={2}>
          {item.title}
        </AppTitle>
        <SC.Description
          dangerouslySetInnerHTML={{ __html: item.short_description }}
        ></SC.Description>
        <SC.Detail to={linkPath} as={Link}>
          {_t("read_more")}
        </SC.Detail>
      </SC.CardContent>
    </SC.Card>
  );
};

export default Card;
