import React from "react";
import * as SC from "./styles";
import { Link } from "react-router-dom";
import { ButtonInterface } from "./types";

const Button: React.FC<ButtonInterface> = ({
  type = "default",
  path,
  htmlType = "button",
  onClick,
  isBorder = false,
  children,
}) => {
  if (type === "outer-link")
    return (
      <SC.Button
        $isBorder={isBorder}
        as="a"
        href={path}
        target="_blank"
        rel="noreferrer"
      >
        <span>{children}</span>
      </SC.Button>
    );
  if (type === "inner-link")
    return (
      <SC.Button $isBorder={isBorder} as={Link} to={path}>
        <span>{children}</span>
      </SC.Button>
    );
  return (
    <SC.Button $isBorder={isBorder} type={htmlType} onClick={onClick}>
      <span>{children}</span>
    </SC.Button>
  );
};

export default Button;
