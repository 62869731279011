import React from "react";
import { _t } from "modules/Common/components/InjectIntlContext";
import * as SC from "./styles";
import { AppText } from "pages/outer/components";

const OptionsList = ({ list }) => {
  const translatedList = list(_t);
  return (
    <SC.OptionsList>
      {translatedList.map((item, idx) => (
        <SC.OptionItem key={idx}>
          <AppText size={1}>{item}</AppText>
        </SC.OptionItem>
      ))}
    </SC.OptionsList>
  );
};

export default OptionsList;
