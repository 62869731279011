import { inputsValidation } from "pages/outer/auth/constants";

export const contactsFields = (t) => [
  {
    name: "name",
    placeholder: t("Name"),
    rules: inputsValidation.name(t),
  },
  {
    name: "email",
    placeholder: t("Email"),
    rules: inputsValidation.email(t),
  },
  {
    name: "text",
    placeholder: t("your_message"),
    rules: inputsValidation.textarea(t),
    type: "textarea",
  },
];
