import styled from "styled-components";

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li`
  + li {
    margin-left: 20px;
  }
`;
