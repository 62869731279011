import styled from "styled-components";

export const Footer = styled.footer`
  background: ${(props) => props.theme.colors.darkCard2};
  margin-top: auto;
  position: relative;
  z-index: 99;
`;
export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 55px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 15px;
  }
`;
