import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserId } from "../../system/helpers/localstorageHelper";
import { getUserBasic } from "../User/actions";
import { Spin } from "antd";
import { ThemeProvider } from "styled-components";
import { themeConfig } from "system/themes";
import { AppTheme } from "../../layouts";

const MainTemplate: PropsWithChildren<any> = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const user_id = getUserId();
    if (user_id) {
      setLoading(true);
      dispatch(getUserBasic({ user_id }))
        .then((res) => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className={"h-100per fx-center fxDirCol"}>
        <Spin
          spinning={isLoading}
          style={{ alignItems: "center", justifyContent: "center" }}
        ></Spin>
      </div>
    );
  }

  return <>{children}</>;
};

export default MainTemplate;
