import React from "react";
import * as SC from "./styles";
import { MapCard } from "./components";
import { MapCardsInterface } from "./types";

const GridItem = (item, idx) => (
  <SC.Item key={idx}>
    <MapCard
      title={item.title}
      path={item.path}
      text={item.text}
      buttonText={item.buttonText}
      buttonType={item.buttonType || "inner-link"}
      image={item.image}
    />
  </SC.Item>
);
const MapCards: React.FC<MapCardsInterface> = ({ cardsList }) => {
  return <SC.Grid>{cardsList.map(GridItem)}</SC.Grid>;
};

export default MapCards;
