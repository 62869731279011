import React, { useEffect, useState } from "react";
import * as SC from "./styles";
import { mediaQueries } from "system/themes";
import { isAuthorized } from "system/helpers/localstorageHelper";
import { AuthButtons, LangDropdown, Logo } from "pages/outer/components";
import { MobileMenu } from "layouts/outer/components";
import { isWindowWidth } from "common/helpers";
import { toggleMobileMenu } from "./helpers";
import { HeaderMenu, BurgerButton } from "./components";

const Header: React.FC = () => {
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const isLogged = isAuthorized();

  useEffect(() => {
    const handleMobileVersion = () => {
      setIsMobileLayout(isWindowWidth(`<=${mediaQueries.laptopSmall}`));
    };
    handleMobileVersion();
    window.addEventListener("resize", handleMobileVersion);
    return () => window.removeEventListener("resize", handleMobileVersion);
  }, []);

  const toggleMenu = () => {
    toggleMobileMenu(isMobileMenuOpened, setIsMobileMenuOpened);
  };

  return (
    <>
      <SC.Header>
        <SC.HeaderContent>
          <Logo />
          {!isMobileLayout && (
            <>
              <HeaderMenu />
              <LangDropdown />
              <AuthButtons />
            </>
          )}
          {isMobileLayout && <BurgerButton toggleMenu={toggleMenu} />}
        </SC.HeaderContent>
      </SC.Header>
      {isMobileMenuOpened && <MobileMenu toggleMenu={toggleMenu} />}
    </>
  );
};

export default Header;
