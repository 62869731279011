export const isWindowWidth = (value) => {
  const numberFromValue = value.replace(/\D/g, "");
  if (value.startsWith("<="))
    return (
      window.innerWidth < +numberFromValue ||
      window.innerWidth === +numberFromValue
    );
  if (value.startsWith(">="))
    return (
      window.innerWidth < +numberFromValue ||
      window.innerWidth === +numberFromValue
    );

  if (value.startsWith("<")) return window.innerWidth < +numberFromValue;
  if (value.startsWith(">")) return window.innerWidth > +numberFromValue;
};
