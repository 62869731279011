import styled from "styled-components";

export const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.fontPrimeColor};
  transition: all 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.primeColor};
  }
  &:active {
    transform: scale(0.95);
  }
`;

export const NavLink = styled(Link)`
  &.active {
    color: ${(props) => props.theme.colors.primeColor};
    transform: scale(1.1);
    text-shadow: 0 0 10px ${(props) => props.theme.colors.darkYellow};
    &:hover {
      border-color: transparent;
    }
  }
`;
