import React from "react";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import { AppTitle } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { getLocale } from "system/helpers/localstorageHelper";
import { useApiQuery } from "common/hooks/useApiQuery";
import { API_TESTIMONIALS } from "./constants";
import { Card } from "./components";

const Testimonials = () => {
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    API_TESTIMONIALS.key,
    API_TESTIMONIALS.path,
    "get",
    {
      params: {
        language: locale,
      },
    }
  );
  return (
    <DefaultPage>
      <Container>
        <AppTitle pageTitle size={1} lvl={1}>
          {_t("testimonials")}
        </AppTitle>
        {data &&
          data?.testimonials.map((item) => <Card key={item.id} item={item} />)}
      </Container>
    </DefaultPage>
  );
};

export default Testimonials;
