import React from "react";
import * as SC from "./styles";
import { AppLinkInterface } from "./types";
import { Link, NavLink } from "react-router-dom";

const AppLink: React.FC<AppLinkInterface> = ({
  path,
  text,
  type = "inner",
}) => {
  if (type === "outer")
    return (
      <SC.Link href={path} target="_blank" rel="noopener">
        {text}
      </SC.Link>
    );

  if (type === "navigation")
    return (
      <SC.NavLink as={NavLink} to={path}>
        {text}
      </SC.NavLink>
    );

  return (
    <SC.Link as={Link} to={path}>
      {text}
    </SC.Link>
  );
};

export default AppLink;
