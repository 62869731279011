import React from "react";
import * as SC from "./styles";
import { Container } from "layouts/outer/components";
import { AppTitle } from "pages/outer/components";
import { SectionInterface } from "./types";

const Section: React.FC<SectionInterface> = ({
  title,
  additionClass,
  children,
}) => {
  return (
    <SC.Section $additionClass={additionClass}>
      <Container>
        <SC.TitleWrap>
          <AppTitle>{title}</AppTitle>
        </SC.TitleWrap>
        {children}
      </Container>
    </SC.Section>
  );
};

export default Section;
