import styled, { css } from "styled-components";
import { Menu } from "layouts/outer/components/NavMenu/styles";

export const FooterMenu = styled.nav`
  margin-left: auto;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 100%;
  }

  ${() => css`
    ${Menu} {
      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;

        > * {
          + * {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
  `}
`;
