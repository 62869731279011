import styled, { css } from "styled-components";

export const Nav = styled.ul`
  display: inline-flex;
  background: ${(props) => props.theme.colors.dark};
  border-radius: 18px;
  margin-bottom: 30px;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    flex-direction: column;
    width: 100%;
  }
`;
export const NavItem = styled.li`
  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    width: 100%;
  }
`;
export const NavButton = styled.button`
  font-size: 24px;
  display: flex;
  padding: 20px 30px;
  line-height: 35px;
  background: transparent;
  border-radius: 18px;
  border: none;
  color: ${(props) => props.theme.colors.fontSecondaryColor};
  font-family: ${(props) => props.theme.fonts.bold};
  transition: all 0.3s ease;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    font-size: 18px;
    padding: 10px 15px;
    line-height: 25px;
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  &:hover {
    color: ${(props) => props.theme.colors.fontPrimeColor};
  }
  ${(props) =>
    props.$active &&
    css`
      background: ${(props) => props.theme.colors.primeColor};
      color: ${(props) => props.theme.colors.fontContrast};
      pointer-events: none;
      &:hover {
        color: ${(props) => props.theme.colors.fontContrast};
      }
    `}
`;
