import React from "react";
import * as SC from "./styles";
import { Input } from "antd";

const AppInput = (props) => {
  const { rules, dependencies, hidden, type, placeholder, name } = props;

  const getInputType = () => {
    switch (type) {
      case "password":
        return Input.Password;
      case "textarea":
        return Input.TextArea;
      default:
        return Input;
    }
  };
  return (
    <SC.InputWrap
      name={name}
      rules={rules}
      dependencies={dependencies}
      hidden={hidden}
    >
      <SC.InputElement
        placeholder={placeholder}
        as={getInputType()}
        type={type}
        $isTextarea={type === "textarea"}
        autoComplete="none"
      />
    </SC.InputWrap>
  );
};

export default AppInput;
