import React from "react";
import * as SC from "./styles";
import { AppTextInterface } from "./types";

const AppText: React.FC<AppTextInterface> = ({
  size,
  as,
  secondary,
  children,
}) => {
  return (
    <SC.Text $size={size} $as={as} $secondary={secondary}>
      {children}
    </SC.Text>
  );
};

export default AppText;
