import React from "react";
import { Container } from "layouts/outer/components";
import { Button } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { AppTitle, AppText } from "pages/outer/components";
import { SlideInterface } from "../../types";
import * as SC from "./styles";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";

const Slide: React.FC<SlideInterface> = ({ title, text, bannerImage }) => {
  return (
    <Container>
      <SC.SlideImage>
        <img src={bannerImage} alt="" />
      </SC.SlideImage>
      <SC.Slide>
        <SC.SlideContent>
          <AppTitle lvl={2} size={1}>
            {title}
          </AppTitle>
          <AppText size={1}>{text}</AppText>
          <Button path={APP_ROUTES.registration} type="outer-link">
            {_t("get_started")}
          </Button>
        </SC.SlideContent>
      </SC.Slide>
    </Container>
  );
};

export default Slide;
