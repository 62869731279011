import React from "react";
import { FormPage } from "layouts/outer";
import { ContactsImage, Form } from "./components";
import { _t } from "modules/Common/components/InjectIntlContext";

const Contacts = () => {
  return (
    <FormPage AdditionalImage={ContactsImage} title={_t("get_in_touch")}>
      <Form />
    </FormPage>
  );
};

export default Contacts;
