export const inputsValidation = {
  login: (t) => [
    {
      required: true,
      message: t("enter_login_message"),
      whitespace: true,
    },
  ],
  password: (t) => [
    {
      required: true,
      message: t("input_password_message"),
    },
    {
      min: 6,
      message: t("min_password_message"),
    },
  ],
  confirm_password: (t, specialValidationMsg) => [
    {
      required: true,
      message: t("confirm_password_message"),
    },
    ({ getFieldValue }) => {
      // t("error_confirm_password_message")
      return {
        validator(rule, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(specialValidationMsg);
        },
      };
    },
  ],
  email: (t) => [
    {
      type: "email",
      message: t("not_valid_email_message"),
    },
    {
      required: true,
      message: t("input_email_message"),
    },
  ],
  phone: (t) => [{ required: true, message: t("Please input your contacts!") }],
  terms: (msg) => [
    {
      validator: (_, value) =>
        value ? Promise.resolve() : Promise.reject(msg),
    },
  ],
  recaptcha: (t) => [
    {
      required: true,
      message: t("recaptcha_required"),
    },
  ],
  name: (t) => [
    {
      required: true,
      message: t("input_name"),
    },
  ],
  textarea: (t) => [
    {
      required: true,
      message: t("Please input details"),
    },
  ],
};
