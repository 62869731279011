import React from "react";
import * as SC from "./styles";
import { AppTitle } from "pages/outer/components";

const Card = ({ item }) => {
  return (
    <SC.Card>
      <AppTitle lvl={3} size={2}>
        {item.title}
      </AppTitle>
      <SC.Text dangerouslySetInnerHTML={{ __html: item.content }}></SC.Text>
      <a href={item.url} target="_blank" rel="noopener noreferrer nofollow">
        {item.url}
      </a>
    </SC.Card>
  );
};

export default Card;
