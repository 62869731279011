import styled from "styled-components";

export const Contacts = styled.div`
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: auto !important;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    width: 100% !important;
    top: auto;
    right: 0;
    bottom: 0;
    transform: none;
    display: flex;
  }
`;

export const Item = styled.a`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.dark};
  margin-bottom: 15px;
  transition: all 0.2s ease;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    border-radius: 0;
    flex: 1;
    margin-bottom: 0;
  }

  svg {
    max-width: 25px;

    path {
      fill: ${(props) => props.theme.colors.primeColor};
      transition: all 0.2s ease;
    }
  }

  &:hover {
    background: ${(props) => props.theme.colors.primeColor};
    svg {
      path {
        fill: ${(props) => props.theme.colors.dark};
      }
    }
  }
`;
