import { REPORT_DATE_KEY } from "../utils";

export const actionTypes = {
  UPDATE_FILTERS: "reports/geo-report/update-filters",
};

export const updateReportFilters = (filters) => {
  if (filters.date) {
    localStorage.setItem(REPORT_DATE_KEY, JSON.stringify(filters.date));
  }
  return {
    type: actionTypes.UPDATE_FILTERS,
    payload: {
      programs: filters.programs_filter,
      ...filters,
    },
  };
};
