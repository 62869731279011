import React from "react";
import * as SC from "./styles";
import { Card } from "../Card";

const Grid = ({ data }) => {
  return (
    <SC.Grid>
      {data.map((item) => (
        <SC.GridItem key={item.id}>
          <Card faq={item} />
        </SC.GridItem>
      ))}
    </SC.Grid>
  );
};

export default Grid;
