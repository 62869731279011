import React from "react";
import { AppTitle, TournamentsList } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";

const Tournaments = () => {
  return (
    <DefaultPage>
      <Container>
        <AppTitle pageTitle lvl={1} size={1}>
          {_t("tournaments")}
        </AppTitle>
        <TournamentsList />
      </Container>
    </DefaultPage>
  );
};

export default Tournaments;
