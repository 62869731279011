import React from "react";
import { CommissionPage } from "layouts/outer";
import { _t } from "modules/Common/components/InjectIntlContext";
import { pageImage } from "./images";
import { commissionOptions } from "./constants";

const CommissionHybrid = () => {
  return (
    <CommissionPage
      title={_t("hybrid_program")}
      image={pageImage}
      optionsList={commissionOptions}
    />
  );
};

export default CommissionHybrid;
