import styled from "styled-components";

export const LanguageElement = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: right;
  background: none;
  width: 100%;
  transition: color 0.2s ease;
  line-height: 40px;
  color: ${(props) => props.theme.colors.fontPrimeColor};
  font-family: inherit;
  font-size: 16px;

  img {
    margin-left: 12px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primeColor};
  }
`;
