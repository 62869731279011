import React from "react";
import { MapCards } from "pages/outer/components";
import { mainMapCardsList } from "./constsnts";
import { Container } from "layouts/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import * as SC from "./styles";

const MainMapCards: React.FC = () => {
  const translatedList = mainMapCardsList(_t);
  return (
    <SC.Section>
      <Container>
        <MapCards cardsList={translatedList} />
      </Container>
    </SC.Section>
  );
};

export default MainMapCards;
