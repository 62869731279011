import React from "react";
import * as SC from "./styles";
import { BurgerButtonInterface } from "./types";

const BurgerButton: React.FC<BurgerButtonInterface> = ({ toggleMenu }) => {
  const openMenu = () => {
    document.querySelector("body").style.overflow = "hidden";
    toggleMenu();
  };
  return <SC.Button onClick={openMenu} />;
};

export default BurgerButton;
