import React, { useState } from "react";
import * as SC from "./styles";
import { Nav, TabContent } from "./components";
import { AppTitle } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";

const HowItWorks: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <SC.Section>
      <SC.Content>
        <TabContent activeTab={activeTab} />
        <SC.Wrap>
          <AppTitle lvl={2}>{_t("how_it_works_section_title")}</AppTitle>
          <Nav tabsInfo={{ activeTab, setActiveTab }} />
        </SC.Wrap>
      </SC.Content>
    </SC.Section>
  );
};

export default HowItWorks;
