import styled from "styled-components";

export const Card = styled.div`
  background: ${(props) => props.theme.colors.darkCard};
  box-shadow: 0px 40px 50px -20px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  overflow: hidden;
  margin-bottom: 28px;
`;
export const Header = styled.div`
  border-radius: 0 0 18px 18px;
  background: ${(props) => props.theme.colors.darkCard2};
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0 25px;
`;
export const CardContent = styled.div`
  padding: 25px;

  table {
    width: 100%;
    color: ${(props) => props.theme.colors.fontSecondaryColor};

    td {
      vertical-align: middle;
      &:first-child {
        width: 40px;
      }
    }

    thead {
      td {
        padding-bottom: 10px;
      }
    }
    tbody {
      td {
        padding: 5px 0;
        color: ${(props) => props.theme.colors.gray};
      }
    }
  }
`;
