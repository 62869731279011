import React from "react";
import { DetailsBox } from "../DetailsBox";
import { _t } from "modules/Common/components/InjectIntlContext";
const PrizesInfo = ({ prizes }) => {
  const prizesContent = prizes.map((item) => (
    <tr key={item.position}>
      <td>{item.position}</td>
      <td>{item.information}</td>
    </tr>
  ));
  const tableInfo = {
    columns: ["#", _t("information")],
    content: prizesContent,
  };

  return <DetailsBox title={_t("prizes")} tableInfo={tableInfo} />;
};

export default PrizesInfo;
