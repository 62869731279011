import React from "react";
import { LogoInterface } from "./types";
import { logo, footerLogo } from "./images";
import * as SC from "./styles";

const Logo: React.FC<LogoInterface> = ({ isFooterLogo, isBigLogo }) => {
  return (
    <SC.Logo $isBigLogo={isBigLogo} to="/">
      <img src={isFooterLogo ? footerLogo : logo} alt="" />
    </SC.Logo>
  );
};

export default Logo;
