import React from "react";
import * as SC from "./styles";
import { Button } from "pages/outer/components";
import { AppText, AppTitle } from "pages/outer/components";

const MapCard = ({ title, text, buttonText, buttonType, path, image }) => {
  return (
    <SC.Card>
      <SC.Content>
        <AppTitle size={2} lvl={3}>
          {title}
        </AppTitle>
        <AppText>{text}</AppText>
        <SC.ButtonWrap>
          <Button type={buttonType} path={path} isBorder>
            {buttonText}
          </Button>
        </SC.ButtonWrap>
      </SC.Content>
      <SC.Image>
        <img src={image} alt="" />
      </SC.Image>
    </SC.Card>
  );
};

export default MapCard;
