import React from "react";
import * as SC from "./styles";
import { contactsImage } from "../../images";

const ContactsImage = () => {
  return (
    <SC.Image>
      <img src={contactsImage} alt="" />
    </SC.Image>
  );
};

export default ContactsImage;
