import { _t } from "modules/Common/components/InjectIntlContext";
import { useMutation } from "react-query";
import { notify } from "system/utils/notification";
import { postContacts } from "../api";

export const usePostContacts = () => {
  const messageSentTranslated = _t("message_sent");
  return useMutation(postContacts, {
    onSuccess: (data, variables) => {
      if (data.status === "ok") {
        notify("success", messageSentTranslated);
      } else {
        Object.keys(data.message).map((errorKey) => {
          notify("error", data.message[errorKey]);
        });
      }
    },
    onError: (error) => {
      notify("error", "error");
    },
  });
};
