import React from "react";
import { DetailsBox } from "../DetailsBox";
import { _t } from "modules/Common/components/InjectIntlContext";
const ResultInfo = ({ result }) => {
  const resultContent = result.map((item) => (
    <tr key={item.position}>
      <td>{item.position}</td>
      <td>{item.user_id}</td>
      <td>{item.value}</td>
    </tr>
  ));
  const tableInfo = {
    columns: ["#", _t("affiliate_id"), _t("value")],
    content: resultContent,
  };

  return <DetailsBox title={_t("prizes")} tableInfo={tableInfo} />;
};

export default ResultInfo;
