import { how_1, how_2, how_3, how_4 } from "./images";

export const HowItWorksNavList = (t) => [
  {
    title: t("how_it_works_nav_1"),
    image: how_1,
  },
  {
    title: t("how_it_works_nav_2"),
    image: how_2,
  },
  {
    title: t("how_it_works_nav_3"),
    image: how_3,
  },
  {
    title: t("how_it_works_nav_4"),
    image: how_4,
  },
];
