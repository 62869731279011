import styled, { css } from "styled-components";
import { DropdownWrap } from "./components";

export const LangDropdownElement = styled.div`
  position: relative;
  margin-left: 60px;

  ${() => css`
    ${DropdownWrap} {
      opacity: 0;
      visibility: hidden;
      filter: alpha(opacity=0);
      transform: translateY(10px);
      transition: all 0.2s ease;
    }

    &:hover {
      ${DropdownWrap} {
        opacity: 1;
        visibility: visible;
        filter: alpha(opacity=100);
        transform: translateY(0px);
      }
    }
  `}
`;
