import styled, { css } from "styled-components";
import { Form, Input } from "antd";

export const InputElement = styled(Input)``;
export const InputPasswordElement = styled(Input)``;

export const InputWrap = styled(Form.Item)`
  margin-bottom: 25px;

  &.ant-form-item-has-error {
    .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
      background: none;
    }
  }

  &.ant-form-item-has-success {
    box-shadow: none;
    input.ant-input {
      background: ${(props) => props.theme.colors.darkCard};
      border-color: ${(props) => props.theme.colors.fontSecondaryColor};
      box-shadow: none;
    }
  }

  .ant-form-item-explain {
    position: absolute;
    left: 15px;
    top: calc(100% + 5px);
    font-size: 11px;
    color: #ff4d4f;
    padding: 0;
  }

  .ant-input-affix-wrapper {
    padding: 0;
    position: relative;
    box-shadow: none !important;
    border: none !important;
    background: none !important;

    .ant-input-suffix {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      .anticon {
        color: ${(props) => props.theme.colors.fontSecondaryColor};
        &:hover {
          color: ${(props) => props.theme.colors.fontPrimeColor};
        }
      }
    }
  }
  input.ant-input,
  textarea.ant-input {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0 15px;
    font-family: ${(props) => props.theme.fonts.regular};
    color: ${(props) => props.theme.colors.fontPrimeColor};
    background: ${(props) => props.theme.colors.darkCard};
    border: 1px solid #30333d;
    border-radius: 8px !important;
    ::placeholder {
      color: ${(props) => props.theme.colors.fontSecondaryColor};
    }
    &:focus,
    &:hover {
      border-color: ${(props) => props.theme.colors.fontSecondaryColor};
      box-shadow: none;
    }
  }

  textarea.ant-input {
    padding-top: 12px;
    min-height: 90px !important;
  }
`;
