import { banner_1, banner_2, banner_3, banner_4 } from "./images";
import { onGetStarted } from "modules/Landing/constants";

export const bannerSlides = (_t: any) => [
  {
    title: _t("slide_1_title"),
    text: _t("slide_1_text"),
    bannerImage: banner_1,
    buttonCallback: onGetStarted,
  },
  {
    title: _t("slide_2_title"),
    text: _t("slide_2_text"),
    bannerImage: banner_2,
    buttonCallback: onGetStarted,
  },
  {
    title: _t("slide_3_title"),
    text: _t("slide_3_text"),
    bannerImage: banner_3,
    buttonCallback: onGetStarted,
  },
  {
    title: _t("slide_4_title"),
    text: _t("slide_4_text"),
    bannerImage: banner_4,
    buttonCallback: onGetStarted,
  },
];
export const sliderSettings = {
  dots: true,
  fade: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
};
