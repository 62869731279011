import styled from "styled-components";
import { listMarker } from "../../images";

export const List = styled.ul`
  margin-bottom: 35px;
`;
export const ListItem = styled.li`
  + * {
    margin-top: 25px;
  }

  position: relative;
  padding-left: 30px;

  &::before {
    content: "";
    background: url(${listMarker}) no-repeat center / contain;
    height: 17px;
    width: 13px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;
export const ListItemText = styled.h5``;
