import styled, { css } from "styled-components";
import { bg } from "./images";

export const PageContent = styled.main`
  position: relative;
  z-index: 2;
`;

export const Page = styled.article`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;

  > * {
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    padding-bottom: 45px;
  }

  ${(props) =>
    props.$hasBackground &&
    css`
      padding-top: 150px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        padding-top: 0;

        ${PageContent} {
          padding-top: 60px;
        }
      }
      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        ${PageContent} {
          padding-top: 30px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 620px;
        max-height: 100%;
        background: url(${bg}) no-repeat center / 100% 620px;

        @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
          background-size: cover;
        }
      }
    `}
`;
