import React from "react";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import * as SC from "./styles";
import { getLocale } from "system/helpers/localstorageHelper";
import { useApiQuery } from "common/hooks/useApiQuery";
import { TERMS_API } from "./constants";
import { AppTitle } from "../components";
import { _t } from "modules/Common/components/InjectIntlContext";

const Terms = () => {
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    TERMS_API.key,
    TERMS_API.path,
    "get",
    {
      params: {
        language: locale,
        name: "conditions",
      },
    }
  );
  return (
    <DefaultPage>
      <Container>
        <SC.TitleWrap>
          <AppTitle pageTitle>{_t("conditions")}</AppTitle>
        </SC.TitleWrap>
        <SC.Wrap dangerouslySetInnerHTML={{ __html: data?.text }}></SC.Wrap>
      </Container>
    </DefaultPage>
  );
};

export default Terms;
