import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";
import { Text } from "pages/outer/components/AppText/styles";

export const Wrap = styled.div`
  position: relative;
  min-height: 500px;
  padding-bottom: 60px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    min-height: 400px;
  }
  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    min-height: 300px;
  }
  ${() => css`
    ${Title} {
      margin-bottom: 25px;
    }

    ${Text} {
      margin-bottom: 55px;
      color: ${(props) => props.theme.colors.gray};
      max-width: 565px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        max-width: 360px;
      }
    }
  `}
`;
export const Image = styled.div`
  position: absolute;
  right: 0;
  top: -50px;
  width: 438px;
  max-width: 100%;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 300px;
    top: 0;
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    width: 180px;
    top: auto;
    bottom: 50px;
    right: 0;
  }

  img {
    object-fit: contain;
  }
`;
export const GridWrap = styled.div`
  margin-top: -40px;
  padding-bottom: 100px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding-bottom: 50px;
  }
`;
