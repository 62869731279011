import styled, { css } from "styled-components";
import { bg } from "./images";
import { Title } from "pages/outer/components/AppTitle/styles";

export const Page = styled.article`
  width: 100%;
  overflow: hidden;

  ${() => css`
    ${Title} {
      margin-bottom: 10px;
    }
  `}
`;

export const Sidebar = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 568px;
  background: url(${bg}) no-repeat center / 100% 100%;
  padding: 30px 50px;
`;
export const ContentWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: flex-start;
  padding: 30px;
  padding-left: 568px;
  overflow-y: auto;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding: 20px;
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
  }
`;
export const Content = styled.div`
  width: 360px;
  max-width: 100%;
  margin: auto;
`;
