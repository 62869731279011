import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";
import { PageContent } from "layouts/outer/DefaultPage/styles";
import { pageBg } from "./images";

export const Wrap = styled.div`
  position: relative;
  min-height: 900px;
  padding-top: 150px;
  padding-bottom: 130px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    min-height: initial;
  }

  &::before {
    content: "";
    position: fixed;
    width: 721px;
    left: 0;
    top: 0;
    height: 100%;
    background: url(${pageBg}) no-repeat center / 100% 100%;

    @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
      width: 300px;
      opacity: 0.75;
    }
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding-top: 30px;
  }
`;
export const Content = styled.div`
  position: relative;
  width: 760px;
  max-width: 100%;
  margin-left: auto;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 100%;
  }
  ${() => css`
    ${Title} {
      margin-bottom: 30px;
    }
  `}
`;
export const Image = styled.div`
  width: 556px;
  position: absolute;
  left: 0;
  top: 100px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopMd}) {
    width: 400px;
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 250px;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }
`;
export const ButtonsWrap = styled.div`
  display: flex;
  margin-top: 50px;

  > * + * {
    margin-left: 15px;
  }
`;
