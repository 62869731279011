import React from "react";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import * as SC from "./styles";
import { getLocale } from "system/helpers/localstorageHelper";
import { useApiQuery } from "common/hooks/useApiQuery";
import { AppTitle } from "../components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { POSTBACK_API } from "./constants";

const PostBackOptions = () => {
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    POSTBACK_API.key,
    POSTBACK_API.path,
    "get",
    {
      params: {
        language: locale,
        name: "faq-postback",
      },
    }
  );
  return (
    <DefaultPage>
      <Container>
        <SC.TitleWrap>
          <AppTitle pageTitle>{_t("postback_options")}</AppTitle>
        </SC.TitleWrap>
        <SC.Wrap dangerouslySetInnerHTML={{ __html: data?.text }}></SC.Wrap>
      </Container>
    </DefaultPage>
  );
};

export default PostBackOptions;
