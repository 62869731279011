import styled from "styled-components";
export const TitleWrap = styled.div`
  text-align: center;
`;
export const Wrap = styled.div`
  width: 794px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;

  strong {
    display: block;
    font-family: ${(props) => props.theme.fonts.bold};
  }

  br {
    content: "";
    display: block;
    margin: 20px 0;
  }
`;
