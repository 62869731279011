import React from "react";
import "./index.scss";
import { renderCurrency } from "../../../../constants";
import { _t } from "../../../../../Common/components/InjectIntlContext";
import classNames from "classnames";
import { CurrencyLabel } from "../../../../../Common/components";
import { getCurrencySymbol } from "../../../../../../common/helpers";

type Type = {
  assured_income: number;
  rs_income: number;
  partner_income: number;
  tableDetails?: boolean;
  symbol?: string;
};
const CellPartnerIncome: React.FC<Type> = ({
  assured_income,
  rs_income,
  partner_income,
  tableDetails,
  symbol,
}) => {
  const isDetailsShowed = tableDetails || rs_income > 0 || assured_income > 0;
  return (
    <div
      className={classNames("cell-partner-income", {
        "cell-partner-income--details": tableDetails,
      })}
    >
      <div className="cell-partner-income__total">
        {!tableDetails && (
          <p className="cell-partner-income__total-title">
            {_t("partner_income")}
          </p>
        )}
        <p className="cell-partner-income__total-value">
          <CurrencyLabel value={partner_income} symbol={symbol} />
        </p>
      </div>
      {isDetailsShowed && (
        <ul className="partner-income-details">
          {(tableDetails || assured_income > 0) && (
            <li className="partner-income-details__item">
              <p className="partner-income-details__title">
                {_t("assured_income")}
              </p>
              <span className="partner-income-details__line"></span>
              <p className="partner-income-details__value">
                <CurrencyLabel value={assured_income} symbol={symbol} />
              </p>
            </li>
          )}
          {(tableDetails || rs_income > 0) && (
            <li className="partner-income-details__item">
              <p className="partner-income-details__title">{_t("rs_income")}</p>
              <span className="partner-income-details__line"></span>
              <p className="partner-income-details__value">
                <CurrencyLabel value={rs_income} symbol={symbol} />
              </p>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CellPartnerIncome;
