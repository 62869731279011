import React from "react";
import * as SC from "./styles";
import { Logo } from "pages/outer/components";
import { Copyright, FooterMenu } from "./components";

const Footer: React.FC = () => {
  return (
    <SC.Footer>
      <SC.FooterContent>
        <Logo isFooterLogo />
        <Copyright />
        <FooterMenu />
      </SC.FooterContent>
    </SC.Footer>
  );
};

export default Footer;
