import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fonts.regular}, sans-serif;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.fontPrimeColor};
    //overflow-x: hidden;
    font-size: 14px;
    line-height: 1.25;  
  }
`;
