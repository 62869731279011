import React from "react";
import {
  GlobalReset,
  GlobalFontStyles,
  GlobalStyles,
} from "system/themes/global";

const GlobalStylesSetup = () => {
  return (
    <>
      <GlobalReset />
      <GlobalFontStyles />
      <GlobalStyles />
    </>
  );
};

export default GlobalStylesSetup;
