import React from "react";
import { contactsFields } from "./constants";
import { _t } from "modules/Common/components/InjectIntlContext";
import {
  AppForm,
  AppFormItem,
  AppText,
  InputsList,
} from "pages/outer/components";
import ReCAPTCHA from "react-google-recaptcha";
import { getLocale } from "system/helpers/localstorageHelper";
import { inputsValidation } from "pages/outer/auth/constants";
import config from "system/config";
import { usePostContacts } from "../../hooks";

const Form = () => {
  const locale = getLocale();
  const recaptchaRef = React.createRef();
  const translatedInputs = contactsFields(_t);
  const [SendContacts, { isLoading }] = usePostContacts();

  return (
    <AppForm submitButtonText={_t("Send")} submitData={SendContacts}>
      <AppText secondary>{_t("contacts_welcome_message")}</AppText>
      <InputsList list={translatedInputs} />
      <AppFormItem
        name="g-recaptcha-response"
        rules={inputsValidation.recaptcha(_t)}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.google_recaptcha_sitekey}
          hl={locale}
          theme={"dark"}
        />
      </AppFormItem>
    </AppForm>
  );
};

export default Form;
