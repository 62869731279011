import styled from "styled-components";
import { sliderBg } from "./images";

export const Slider = styled.section`
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background: linear-gradient(
      180deg,
      rgba(29, 32, 41, 0) 0%,
      ${(props) => props.theme.colors.background} 100%
    );
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 50;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 90px;
    z-index: 99;

    @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
      bottom: 60px;
    }

    li {
      width: auto;

      button {
        font-size: 0;
        display: block;
        padding: 0;
        width: 48px;
        height: 8px;
        background: none;
        border-radius: 4px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        transition: all 0.2s ease;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          border-color: transparent;
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .slick-list {
    z-index: 2;
  }
`;
