import styled, { css } from "styled-components";

export const Text = styled.p`
  color: ${(props) => props.theme.colors.fontPrimeColor};
  font-size: ${(props) => {
    switch (props.$size) {
      case 1:
        return "24px";
      default:
        return "14px";
    }
  }};
  line-height: ${(props) => {
    switch (props.$size) {
      case 1:
        return "125%";
      default:
        return "150%";
    }
  }};

  a {
    color: ${(props) => props.theme.colors.primeColor};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    font-size: ${(props) => {
      switch (props.$size) {
        case 1:
          return "18px";
        default:
          return "13px";
      }
    }};
  }

  ${(props) =>
    props.$secondary &&
    css`
      color: ${(props) => props.theme.colors.fontSecondaryColor};
    `}
`;
