import styled from "styled-components";

export const Image = styled.div`
  width: 736px;
  height: 542px;
  position: absolute;
  right: -200px;
  top: 50px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 300px;
    height: 200px;
    right: 0;
    top: 150px;
  }
`;
