import React from "react";
import { _t } from "modules/Common/components/InjectIntlContext";
import { BenefitsGrid } from "./components";
import { Section } from "layouts/outer/components";

const OurBenefits: React.FC = () => {
  return (
    <Section title={_t("our_benefits")} additionClass={"our-benefits"}>
      <BenefitsGrid />
    </Section>
  );
};

export default OurBenefits;
