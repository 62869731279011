import React from "react";
import { useSelector } from "react-redux";
import { getLocale } from "system/helpers/localstorageHelper";
import { Dropdown, Language } from "./components";
import { languagesFlags } from "./constants";
import { LangDropdownElement } from "./styles";

const LangDropdown = () => {
  const locale = getLocale();
  const { languages } = useSelector((state) => state.locale);
  const language = Object.values(languages).filter(
    (item) => item["code"] === locale
  )[0];

  return (
    <LangDropdownElement>
      <Language title={language["name"]} />
      <Dropdown languages={languages} locale={locale} />
    </LangDropdownElement>
  );
};

export default LangDropdown;
