import React from "react";
import * as SC from "./styles";
import { aboutListItems } from "./constants";
import { _t } from "modules/Common/components/InjectIntlContext";
import { AppText } from "pages/outer/components";

const List = () => {
  const translatedList = aboutListItems(_t);
  return (
    <SC.List>
      {translatedList.map((item, idx) => (
        <SC.ListItem key={idx}>
          <AppText size={1} as="h4">
            {item}
          </AppText>
        </SC.ListItem>
      ))}
    </SC.List>
  );
};

export default List;
