import React, { useEffect, useState } from "react";
import { useApiQuery } from "common/hooks/useApiQuery";
import { API_FAQ } from "./constants";
import { getLocale } from "system/helpers/localstorageHelper";
import { Container } from "layouts/outer/components";
import { AppTitle } from "../components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { DefaultPage } from "layouts/outer";
import { Nav, Grid } from "./components";

const FAQ = () => {
  const [filter, setFilter] = useState("general");
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    `${API_FAQ.key}-${filter}`,
    API_FAQ.path,
    "get",
    {
      params: {
        category: filter,
        language: locale,
      },
    }
  );

  return (
    <DefaultPage>
      <Container>
        <AppTitle pageTitle size={1}>
          {_t("FAQ")}
        </AppTitle>
        <Nav filter={filter} setFilter={setFilter} />
        {data && <Grid data={data?.faq} />}
      </Container>
    </DefaultPage>
  );
};

export default FAQ;
