import React, { useEffect, useState } from "react";
import * as SC from "./styles";
import { AppTheme } from "layouts";
import { FormPageInterface } from "./types";
import { AppTitle, Logo } from "pages/outer/components";
import { BackButton, SidebarImage } from "./components";
import { isWindowWidth } from "../../../common/helpers";
import { mediaQueries } from "../../../system/themes";

const FormPage: React.FC<FormPageInterface> = ({
  title,
  children,
  AdditionalImage,
}) => {
  const [isMobileLayout, setIsMobileLayout] = useState(false);

  useEffect(() => {
    const handleMobileVersion = () => {
      setIsMobileLayout(isWindowWidth(`<=${mediaQueries.laptopSmall}`));
    };
    handleMobileVersion();
    window.addEventListener("resize", handleMobileVersion);
    return () => window.removeEventListener("resize", handleMobileVersion);
  }, []);

  return (
    <AppTheme>
      <SC.Page>
        {!isMobileLayout && (
          <SC.Sidebar>
            <Logo isBigLogo />
            <SidebarImage AdditionalImage={AdditionalImage} />
          </SC.Sidebar>
        )}
        <SC.ContentWrapper>
          <SC.Content>
            <BackButton />
            {title && (
              <AppTitle lvl={3} size={2}>
                {title}
              </AppTitle>
            )}
            {children}
          </SC.Content>
        </SC.ContentWrapper>
      </SC.Page>
    </AppTheme>
  );
};

export default FormPage;
