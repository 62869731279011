import styled, { css } from "styled-components";
import { bg } from "./images";
import { Title } from "pages/outer/components/AppTitle/styles";

export const Section = styled.section`
  background: url(${bg}) no-repeat center / cover;
  position: relative;

  &::before {
    content: "";
    background: linear-gradient(
      0deg,
      rgba(29, 32, 41, 0) 0%,
      ${(props) => props.theme.colors.background} 100%
    );
    width: 100%;
    height: 45px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
export const Content = styled.div`
  min-height: 620px;
  position: relative;
  z-index: 5;
  padding-top: 70px;
  padding-bottom: 30px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    min-height: 500px;
  }

  ${() => css`
    ${Title} {
      margin-bottom: 30px;
    }
  `}
`;
