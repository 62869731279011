import { APP_ROUTES } from "system/constants/constantsUrl";

export const footerMenuList = (t) => [
  {
    text: t("conditions"),
    path: APP_ROUTES.terms,
  },
  {
    text: t("Commissions"),
    path: APP_ROUTES.commissions.commission,
  },
  {
    text: t("news"),
    path: APP_ROUTES.news.all,
  },
  {
    text: t("tournaments"),
    path: APP_ROUTES.tournaments.all,
  },
  {
    text: t("FAQ"),
    path: APP_ROUTES.faq,
  },
  {
    text: t("testimonials"),
    path: APP_ROUTES.testimonials,
  },
  {
    text: t("Contacts"),
    path: APP_ROUTES.contacts,
  },
];
