import styled from "styled-components";

export const Tab = styled.div`
  position: relative;
  width: 652px;
  height: 464px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 450px;
    height: 300px;
    margin: 0 auto;
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 100%;
  }
`;
export const Image = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.$isActive ? 10 : 1)};
  opacity: ${(props) => (props.$isActive ? 1 : 0)};

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
