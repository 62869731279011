import React from "react";
import { NavMenu } from "layouts/outer/components/NavMenu";
import { _t } from "modules/Common/components/InjectIntlContext";
import { footerMenuList } from "./constants";
import * as SC from "./styles";

const FooterMenu: React.FC = () => {
  const translatedMenu = footerMenuList(_t);
  return (
    <SC.FooterMenu>
      <NavMenu menuList={translatedMenu} />
    </SC.FooterMenu>
  );
};

export default FooterMenu;
