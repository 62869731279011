import React from "react";
import { useApiQuery } from "common/hooks/useApiQuery";
import { NEWS_API } from "./constants";
import { getLocale } from "system/helpers/localstorageHelper";
import * as SC from "./styles";
import { Card } from "./components";

const NewsGrid = () => {
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    NEWS_API.key,
    NEWS_API.path,
    "get",
    {
      language: locale,
    }
  );
  return (
    <SC.NewsGrid>
      {data?.news?.map((item) => (
        <SC.NewsGridItem key={item.id}>
          <Card post={item} />
        </SC.NewsGridItem>
      ))}
    </SC.NewsGrid>
  );
};

export default NewsGrid;
