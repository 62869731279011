import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";
import { Text } from "pages/outer/components/AppText/styles";

export const Content = styled.div`
  width: 100%;
  height: 285px;
  padding: 25px 35px;
  position: relative;
  z-index: 5;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    padding: 20px 15px;
    height: 245px;
  }
`;
export const Image = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 260px;
  height: 295px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 150px;
    height: 160px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ButtonWrap = styled.div`
  position: absolute;
  left: 35px;
  bottom: 25px;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    left: 15px;
    bottom: 20px;
  }
`;

export const Card = styled.div`
  position: relative;
  border-radius: 18px;
  background: ${(props) => props.theme.colors.darkCard};
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 18px;
      background: ${(props) => props.theme.colors.darkCard};
      opacity: 0.6;
      z-index: 3;
    }
  }

  ${() => css`
    ${Text} {
      max-width: 250px;
      color: ${(props) => props.theme.colors.fontPrimeColor};
      transition: color 0.2s ease;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        color: ${(props) => props.theme.colors.fontPrimeColor};
      }
    }
    ${Title} {
      max-width: 250px;
      color: ${(props) => props.theme.colors.fontPrimeColor};
      transition: color 0.2s ease;
      margin-bottom: 10px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        color: ${(props) => props.theme.colors.fontPrimeColor};
      }
    }
  `}
  &:hover {
    box-shadow: 0 10px 35px -3px rgba(0, 0, 0, 0.5);
  }
`;
