import React from "react";
import * as SC from "./styles";
import { AppTitleInterface } from "./types";

const AppTitle: React.FC<AppTitleInterface> = ({
  lvl = 2,
  size,
  pageTitle,
  children,
}) => {
  return (
    <SC.Title as={`h${lvl}`} $size={size} $pageTitle={pageTitle}>
      {children}
    </SC.Title>
  );
};

export default AppTitle;
