import React from "react";
import { HowItWorksNavList } from "../../constants";
import { _t } from "modules/Common/components/InjectIntlContext";
import * as SC from "./styles";
import { NavElementInterface, NavInterface } from "../../types";

const Nav: React.FC<NavInterface> = ({ tabsInfo }) => {
  const { activeTab, setActiveTab } = tabsInfo;
  const translatedNav: NavElementInterface[] = HowItWorksNavList(_t);
  const handleTabSwitch = (idx) => {
    setActiveTab(idx);
  };
  return (
    <SC.Nav>
      {translatedNav.map((item, idx) => (
        <SC.NavItem key={idx}>
          <SC.Button
            onClick={() => handleTabSwitch(idx)}
            $isActive={idx === activeTab}
          >
            <SC.Text as="span" $size={2}>
              {item.title}
            </SC.Text>
          </SC.Button>
        </SC.NavItem>
      ))}
    </SC.Nav>
  );
};

export default Nav;
