export const mediaQueries = {
  fullHD: "1919px",
  HDPlus: "1679px",
  laptopLarge: "1599px",
  laptopMd: "1439px",
  laptop: "1365px",
  HD: "1279px",
  laptopSmall: "1199px",
  tablet: "1023px",
  tabletSmall: "767px",
  mobile: "567px",
};
export const themeConfig = {
  bettilt: {
    colors: {
      primeColor: "#FFE456",
      secondaryColor: "",
      dark: "#141721",
      gray: "#A0A5B1",
      darkGray: "#6E7584",
      darkYellow: "#83783E",
      darkCard: "#21242D",
      darkCard2: "#16181F",
      fontPrimeColor: "#D9DFEC",
      fontSecondaryColor: "#6E7584",
      fontContrast: "#1D2029",
      background: "#1D2029",
      primeGradient: "linear-gradient(90deg, #FFE456 0%, #FFC656 100%)",
    },
    fonts: {
      regular: "Inter-Regular",
      bold: "Inter-Bold",
    },
    mediaQueries,
  },
};
