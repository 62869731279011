import styled from "styled-components";

export const Card = styled.div`
  background: ${(props) => props.theme.colors.darkCard};
  border-radius: 18px;
  padding: 25px 35px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding: 20px 15px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 125%;
  margin-bottom: 15px;
  font-family: ${(props) => props.theme.fonts.bold};

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    font-size: 18px;
  }
`;
export const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.theme.colors.gray};
`;
