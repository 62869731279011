import styled, { css } from "styled-components";

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.fontPrimeColor};
  font-size: ${(props) => {
    switch (props.$size) {
      case 1:
        return "78px";
      case 2:
        return "36px";
      case 3:
        return "24px";
      default:
        return "56px";
    }
  }};
  line-height: 1;
  font-family: ${(props) => props.theme.fonts.bold};

  ${(props) =>
    props.$pageTitle &&
    css`
      margin-bottom: 50px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        margin-bottom: 25px;
      }
    `};

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    font-size: ${(props) => {
      switch (props.$size) {
        case 1:
          return "56px";
        case 2:
          return "26px";
        case 3:
          return "18px";
        default:
          return "36px";
      }
    }};
  }
  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    font-size: ${(props) => {
      switch (props.$size) {
        case 1:
          return "36px";
        case 2:
          return "21px";
        case 3:
          return "18px";
        default:
          return "26px";
      }
    }};
  }
`;
