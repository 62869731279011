import React from "react";
import * as SC from "./styles";

const Card = ({ faq }) => {
  return (
    <SC.Card>
      <SC.Title
        dangerouslySetInnerHTML={{
          __html: faq.translations[0]?.question.replace(/<[^>]+>/g, ""),
        }}
      ></SC.Title>
      <SC.Text
        dangerouslySetInnerHTML={{
          __html: faq.translations[0]?.answer.replace(/<[^>]+>/g, ""),
        }}
      ></SC.Text>
    </SC.Card>
  );
};

export default Card;
