import React from "react";
import { AppLink } from "pages/outer/components/AppLink";
import * as SC from "./styles";
import { NavMenuInterface } from "./types";

const MenuElement = (item, key: number) => {
  const { path, text } = item;
  return !item.isHidden ? (
    <SC.MenuItem key={key}>
      <AppLink path={path} text={text} type="navigation" />
    </SC.MenuItem>
  ) : null;
};
const NavMenu: React.FC<NavMenuInterface> = ({ menuList }) => {
  return <SC.Menu>{menuList.map(MenuElement)}</SC.Menu>;
};

export default NavMenu;
