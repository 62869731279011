import styled, { css } from "styled-components";
import { main_bg } from "./images";
import { Title as SectionTitle } from "pages/outer/components/AppTitle/styles";

export const Section = styled.section`
  background: url(${main_bg}) no-repeat center / cover;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: -125px;
  border-radius: 25px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    margin-top: -110px;
  }
`;
export const Content = styled.div`
  height: 690px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 50px;
  padding-bottom: 100px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 100px 30px;
    padding-bottom: 50px;
    height: auto;
    min-height: 500px;
  }

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    padding: 80px 15px;
    padding-bottom: 30px;
  }
`;
export const Wrap = styled.div`
  height: 464px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    margin-left: 0;
    margin-bottom: 30px;
    height: auto;
  }

  ${() => css`
    > ${SectionTitle} {
      color: ${(props) => props.theme.colors.fontContrast};
      margin-bottom: 45px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        margin-bottom: 15px;
      }
    }
  `}
`;
