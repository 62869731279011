import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";

export const ArticleWrap = styled.div`
  width: 794px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;

  strong {
    display: block;
    font-family: ${(props) => props.theme.fonts.bold};
  }
  br {
    content: "";
    display: block;
    margin: 20px 0;
  }
  ${() => css`
    ${Title} {
      color: #fff;
      margin-bottom: 40px;
      margin-top: 10px;
      line-height: 1.4;
    }
  `}
`;

export const Image = styled.div`
  position: relative;
  height: 320px;
  margin-bottom: 30px;
  border-radius: 18px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    height: 235px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const Text = styled.div`
  font-size: 24px;
  line-height: 1.35;
  color: #fff;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    font-size: 16px;
  }

  a {
    color: ${(props) => props.theme.colors.primeColor};
    &:hover {
      text-decoration: underline;
    }
  }
`;
