import React from "react";
import * as SC from "./styles";

const Copyright = () => {
  const currentTime = new Date();
  return (
    <SC.Copyright>Bettilt Partners {currentTime.getFullYear()}</SC.Copyright>
  );
};

export default Copyright;
