import React from "react";
import * as SC from "./styles";
import { sideImage } from "../../images";

const SidebarImage = ({ AdditionalImage }) => {
  if (AdditionalImage) return <AdditionalImage />;
  return (
    <SC.Image>
      <img src={sideImage} alt="" />
    </SC.Image>
  );
};

export default SidebarImage;
