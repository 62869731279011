import React from "react";
import { TabContentInterface } from "./types";
import { NavElementInterface } from "../../types";
import { HowItWorksNavList } from "../../constants";
import { _t } from "modules/Common/components/InjectIntlContext";
import * as SC from "./styles";

const TabContent: React.FC<TabContentInterface> = ({ activeTab }) => {
  const translatedNav: NavElementInterface[] = HowItWorksNavList(_t);

  return (
    <SC.Tab>
      {translatedNav.map((item, idx) => (
        <SC.Image key={idx} $isActive={idx === activeTab}>
          <img src={item.image} alt={item.title} />
        </SC.Image>
      ))}
    </SC.Tab>
  );
};

export default TabContent;
