import React from "react";
import { commissionsList } from "./constants";
import { _t } from "modules/Common/components/InjectIntlContext";
import { MapCards } from "../MapCards";
import { CommissionItemInterface } from "./types";

const CommissionsGrid: React.FC = () => {
  const translatedList: CommissionItemInterface[] = commissionsList(_t);
  return <MapCards cardsList={translatedList} />;
};

export default CommissionsGrid;
