import React, { useContext, createContext } from "react";
import { injectIntl } from "react-intl";
import texts from "./../../../system/constants/Translations";

export const IntlContext = createContext(null);

export const InjectIntlContext: React.FC = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
});

type translationParams = {
  [key: string]: any;
};

export type transType = (key: any, param?: translationParams) => string;

export const _t: transType = (key, param = {}) => {
  const intl = useContext(IntlContext);
  const devMode = process.env.NODE_ENV === "development";
  const textVal: any = (texts as { [key: string]: object })[key];
  const intlMessagesVal: any = (intl.messages as { [key: string]: object })[
    key
  ];
  if (!intl) {
    return `#${key}#`;
  }

  if (!devMode) {
    delete textVal?.defaultMessage;
  }

  let transResult: string = "";
  if (textVal) {
    transResult = intl!.formatMessage(textVal, param);
  } else if (intlMessagesVal) {
    transResult = intlMessagesVal;
  } else {
    transResult = `#${key}#`;
  }
  // textVal ? intl!.formatMessage(textVal, param) : key ? `#${key}#` : ""
  return transResult;
};
