import React from "react";
import { LanguageElement } from "./styles";
import { LanguageInterface } from "./types";

const Language: React.FC<LanguageInterface> = ({ flag, title, cb }) => {
  return (
    <LanguageElement onClick={cb}>
      {title}
      {flag && <img src={flag} alt="" />}
    </LanguageElement>
  );
};

export default Language;
