import React from "react";
import { useParams } from "react-router-dom";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import * as SC from "./styles";
import { useApiQuery } from "common/hooks/useApiQuery";
import { ARTICLE_API } from "./constants";
import { getLocale } from "system/helpers/localstorageHelper";
import { AppText, AppTitle } from "../components";
import { Text } from "./styles";

const Article = () => {
  const { id } = useParams();
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    ARTICLE_API.key,
    ARTICLE_API.path,
    "get",
    {
      params: {
        language: locale,
        slug: id,
      },
    }
  );
  return (
    <DefaultPage>
      <Container>
        <SC.ArticleWrap>
          <SC.Image>
            <img
              src={`${data?.post?.host}${data?.post?.options_parsed?.image}`}
              alt=""
            />
          </SC.Image>
          <AppText secondary>{data?.post.post_date}</AppText>
          <AppTitle>{data?.post.caption}</AppTitle>
          <SC.Text
            dangerouslySetInnerHTML={{ __html: data?.post?.text }}
          ></SC.Text>
        </SC.ArticleWrap>
      </Container>
    </DefaultPage>
  );
};

export default Article;
