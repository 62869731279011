import React from "react";
import { GlobalStylesSetup, themeConfig } from "system/themes";
import { AppThemeInterface } from "./types";
import { ThemeProvider } from "styled-components";

const AppTheme: React.FC<AppThemeInterface> = ({ children }) => {
  return (
    <ThemeProvider theme={themeConfig.bettilt}>
      <GlobalStylesSetup />
      {children}
    </ThemeProvider>
  );
};

export default AppTheme;
