import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";

export const Card = styled.div`
  position: relative;
  border-radius: 18px;
  overflow-x: hidden;
  box-shadow: 0px 40px 50px -20px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.colors.darkCard};

  ${() => css`
    ${Title} {
      margin-bottom: 25px;
    }
  `}
`;
export const CardContent = styled.div`
  padding: 25px 35px;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    padding: 20px 15px;
  }
`;
export const Image = styled.div`
  height: 360px;
  position: relative;
  display: block;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    height: 270px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const Description = styled.div`
  font-size: 24px;
  line-height: 1.25;
  color: ${(props) => props.theme.colors.gray};
  word-break: break-all;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    font-size: 16px;
  }

  > * {
    margin: 15px 0;

    @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
      margin: 10px 0;
    }
  }

  ul {
    padding-left: 30px;
    margin: 30px 0;

    @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
      padding-left: 10px;
      margin: 15px 0;
    }

    li {
      position: relative;
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.primeColor};

        @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
          top: 5px;
        }
      }

      + li {
        margin-top: 25px;

        @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
          margin-top: 10px;
        }
      }
    }
  }
`;
export const Date = styled.p`
  display: flex;
  align-items: center;
  color: #3ccc87;
  margin-bottom: 15px;

  span {
    width: 50px;
    margin: 0 15px;
    height: 1px;
    background: #3ccc87;
  }
`;
export const Detail = styled.a`
  color: ${(props) => props.theme.colors.primeColor};
  line-height: 1.25;
  font-size: 16px;
  display: inline-block;

  &:hover {
    color: ${(props) => props.theme.colors.primeColor};
    text-decoration: underline;
  }
`;
