import React from "react";
import { Section } from "layouts/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { CommissionsGrid } from "pages/outer/components";

const Commissions = () => {
  return (
    <Section title={_t("Commissions")}>
      <CommissionsGrid />
    </Section>
  );
};

export default Commissions;
