import styled from "styled-components";
export const DropdownWrap = styled.div`
  position: absolute;
  top: 0;
  right: -14px;
  border-top: 45px solid transparent;
`;

export const DropdownContent = styled.div`
  background: ${(props) => props.theme.colors.darkCard};
  padding: 6px 14px;
  border-radius: 8px;
  width: 160px;
`;
