import React from "react";
import * as SC from "./styles";
import { getLocale } from "system/helpers/localstorageHelper";
import { useApiQuery } from "common/hooks/useApiQuery";
import { API_TOURNAMENTS } from "./constants";
import { Card } from "./components";

const TournamentsList = () => {
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    API_TOURNAMENTS.key,
    API_TOURNAMENTS.path,
    "get",
    {
      params: {
        language: locale,
      },
    }
  );
  return (
    <SC.Grid>
      {data &&
        data?.tournaments?.map((item) => (
          <SC.GridItem key={item.id}>
            <Card item={item} />
          </SC.GridItem>
        ))}
    </SC.Grid>
  );
};

export default TournamentsList;
