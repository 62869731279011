import React, { useEffect, useState } from "react";
import * as SC from "./styles";
import { AppTitle, Button } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { Image, List } from "./components";
import { Container } from "layouts/outer/components";
import config from "system/config";
import { isWindowWidth } from "common/helpers";
import { mediaQueries } from "system/themes";

const AboutUs: React.FC = () => {
  const [isMobileLayout, setIsMobileLayout] = useState(false);

  useEffect(() => {
    const handleMobileVersion = () => {
      setIsMobileLayout(isWindowWidth(`<=${mediaQueries.tabletSmall}`));
    };
    handleMobileVersion();
    window.addEventListener("resize", handleMobileVersion);
    return () => window.removeEventListener("resize", handleMobileVersion);
  }, []);
  return (
    <SC.Section>
      <Container>
        <SC.Content>
          <AppTitle size={1} lvl={1}>
            {_t("about_bettilt")}
          </AppTitle>
          <List />
          <Button path={_t("get_started_link")} type="outer-link">
            {_t("get_started")}
          </Button>
          {!isMobileLayout && <Image />}
        </SC.Content>
      </Container>
    </SC.Section>
  );
};

export default AboutUs;
