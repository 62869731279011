import React from "react";
import * as SC from "./styles";
import { BenefitCard } from "pages/outer/sections/OurBenefits/index";
import { _t } from "modules/Common/components/InjectIntlContext";
import { BenefitInterface } from "../../types";
import { benefitsList } from "../../constants";

const BenefitsGrid: React.FC = () => {
  const translatedList: BenefitInterface[] = benefitsList(_t);
  return (
    <SC.Grid>
      {translatedList.map((item, idx) => {
        const { title, image, text } = item;
        return (
          <SC.GridItem key={idx}>
            <BenefitCard title={title} text={text} image={image} />
          </SC.GridItem>
        );
      })}
    </SC.Grid>
  );
};

export default BenefitsGrid;
