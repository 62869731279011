import { APP_ROUTES } from "system/constants/constantsUrl";
import { isAuthorized } from "system/helpers/localstorageHelper";

export const headerMenuList = (t) => [
  {
    text: t("Commissions"),
    path: APP_ROUTES.commissions.commission,
  },
  {
    text: t("FAQ"),
    path: APP_ROUTES.faq,
  },
  {
    text: t("Contacts"),
    path: APP_ROUTES.contacts,
  },
];
