import React from "react";
import { AppInput } from "pages/outer/components/AppInput";
import * as SC from "./styles";
import { InputsListInterface } from "./types";

const InputsList: React.FC<InputsListInterface> = ({ list }) => {
  return (
    <SC.InputsList>
      {list.map((item, idx) => (
        <AppInput {...item} key={idx} />
      ))}
    </SC.InputsList>
  );
};

export default InputsList;
