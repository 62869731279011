import React from "react";
import * as SC from "./styles";
import { CloseButtonInterface } from "./types";

const CloseButton: React.FC<CloseButtonInterface> = ({ toggleMenu }) => {
  const closeMenu = () => {
    document.querySelector("body").removeAttribute("style");
    toggleMenu();
  };
  return <SC.Button onClick={closeMenu} />;
};

export default CloseButton;
