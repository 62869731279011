import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { _t } from "modules/Common/components/InjectIntlContext";
import { Slide } from "./components";
import * as SC from "./styles";
import { bannerSlides, sliderSettings } from "./constants";
import { SlideInterface } from "./types";

const MainSlider: React.FC = () => {
  const translatedSlides: SlideInterface[] = bannerSlides(_t);
  return (
    <SC.Slider as={Slider} {...sliderSettings}>
      {translatedSlides.map((slide, idx) => (
        <Slide
          title={slide.title}
          text={slide.text}
          bannerImage={slide.bannerImage}
          buttonCallback={slide.buttonCallback}
          key={idx}
        />
      ))}
    </SC.Slider>
  );
};

export default MainSlider;
