import styled, { css } from "styled-components";
import { Menu } from "layouts/outer/components/NavMenu/styles";
import { Menu as HeaderMenu } from "layouts/outer/components/Header/components/HeaderMenu/styles";
import { Wrap as AuthButtonsWrap } from "pages/outer/components/AuthButtons/styles";
import { LangDropdownElement } from "pages/outer/components";

export const MobileMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.dark};
  overflow-x: hidden;
  z-index: 9999;
`;
export const MobileMenuContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  padding-bottom: 100px;

  ${() => css`
    ${LangDropdownElement} {
      margin: 0;
      position: absolute;
      right: 20px;
      top: 15px;
    }

    ${AuthButtonsWrap} {
      margin: 0;
      padding: 0 20px 15px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      > * {
        width: 50%;
      }
    }

    ${HeaderMenu} {
      margin: auto;
    }

    ${Menu} {
      flex-direction: column;
      font-size: 21px;

      > * {
        + * {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }
  `}
`;
