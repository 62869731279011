import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";
import { Text } from "pages/outer/components/AppText/styles";

export const Card = styled.div`
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  background: ${(props) => props.theme.colors.darkCard};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0px 40px 50px -20px rgba(0, 0, 0, 0.5);
  }
`;
export const Image = styled.div`
  height: 285px;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 25px 35px;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    padding: 20px 15px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  ${() => css`
    ${Title} {
      position: relative;
      z-index: 3;
      line-height: 120%;
      text-shadow: 0 0 30px #000;

      a {
        color: #fff;

        &:hover {
          color: ${(props) => props.theme.colors.primeColor};
        }
      }
    }
  `}
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const CardContent = styled.div`
  padding: 25px 35px;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    padding: 20px 15px;
  }
`;
export const CardDescription = styled.div`
  margin-top: 5px;
  ${() => css`
    ${Text} {
      color: ${(props) => props.theme.colors.gray};
    }
  `}
`;
export const ReadMore = styled.div`
  margin-top: 20px;
  a {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primeColor};
    &:hover {
      text-decoration: underline;
    }
  }
`;
