import styled from "styled-components";
import { listMarker } from "../../images";

export const OptionsList = styled.ul``;
export const OptionItem = styled.li`
  + * {
    margin-top: 24px;
  }

  position: relative;
  padding-left: 30px;

  &::before {
    content: "";
    width: 14px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 3px;
    background: url(${listMarker}) no-repeat center / contain;
  }
`;
