import React from "react";
import * as SC from "./styles";
import { Button } from "pages/outer/components";
import { AppFormInterface } from "./types";

const AppForm: React.FC<AppFormInterface> = ({
  submitButtonText,
  submitData,
  children,
  additionalProps,
}) => {
  return (
    <SC.FormElement
      onFinish={submitData}
      {...additionalProps}
      autoComplete="off"
    >
      {children}
      <SC.FormFooter>
        <Button htmlType="submit">{submitButtonText}</Button>
      </SC.FormFooter>
    </SC.FormElement>
  );
};

export default AppForm;
