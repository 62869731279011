import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";

export const Card = styled.div`
  border-radius: 18px;
  padding: 25px 35px;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.15);
  background: ${(props) => props.theme.colors.darkCard};
  margin-bottom: 16px;
  transition: all 0.2s ease;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding: 20px 15px;
  }
  ${() => css`
    ${Title} {
      color: ${(props) => props.theme.colors.fontSecondaryColor};
      margin-bottom: 10px;
      transition: all 0.2s ease;
    }
  `}
  a {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primeColor};

    &:hover {
      color: ${(props) => props.theme.colors.primeColor};
      text-decoration: underline;
    }

    @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
      font-size: 14px;
    }
  }

  &:hover {
    transform: scale(1.025);

    ${() => css`
      ${Title} {
        color: ${(props) => props.theme.colors.primeColor};
      }
    `}
  }
`;
export const Text = styled.div`
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    font-size: 16px;
  }
`;
