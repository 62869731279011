import styled from "styled-components";
import { burgerMenu } from "./images";

export const Button = styled.button`
  border: none;
  background: url(${burgerMenu}) no-repeat center / contain;
  width: 35px;
  height: 35px;
  margin-left: auto;
`;
