import { Mail, Skype, TG } from "./images";

export const contactsItems = [
  // {
  //   path: "https://t.me/bettilt_partners",
  //   Icon: TG,
  // },
  {
    path: "skype:live:.cid.ea79f7eca8d06a4a?chat ",
    Icon: Skype,
  },
  // {
  //   path: "mailto:partners@bettilt.com",
  //   Icon: Mail,
  // },
];
