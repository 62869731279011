import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  * + * {
    margin-left: 15px;
  }
`;
