import React from "react";
import * as SC from "./styles";
import { _t } from "modules/Common/components/InjectIntlContext";
import { ReactComponent as BackIcon } from "../../images/back-icon.svg";
import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();

  return (
    <SC.Button onClick={() => history.goBack()}>
      <BackIcon />
      {_t("Back")}
    </SC.Button>
  );
};

export default BackButton;
