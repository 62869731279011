import styled from "styled-components";

export const Header = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    background: ${(props) => props.theme.colors.dark};
    position: relative;
    box-shadow: 0 0 20px 0 ${(props) => props.theme.colors.dark};
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 55px;
  position: relative;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding: 15px;
  }
`;
