import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";

export const Nav = styled.ul``;
export const NavItem = styled.li``;
export const Button = styled.button`
  padding: 12.5px 0;
  background: none;
  border: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    padding: 6px 0;
  }
  &::before {
    content: "";
    width: 50px;
    height: 4px;
    background: ${(props) => props.theme.colors.fontContrast};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-10px, -50%) scaleX(0);
    opacity: 0;
    transition: all 0.2s ease;
    border-radius: 50px;

    @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
      width: 25px;
    }
  }

  &:hover {
    ${() => css`
      ${Text} {
        color: ${(props) => props.theme.colors.fontContrast};
        transform: translateX(10px);
      }
    `}
  }

  ${(props) =>
    props.$isActive &&
    css`
      &::before {
        transform: translate(0, -50%) scaleX(1);
        opacity: 1;
      }
      ${Text} {
        color: ${(props) => props.theme.colors.fontContrast};
        transform: translateX(70px);

        @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
          transform: translateX(35px);
        }
      }

      &:hover {
        ${Text} {
          color: ${(props) => props.theme.colors.fontContrast};
          transform: translateX(70px);

          @media (max-width: ${(props) =>
              props.theme.mediaQueries.tabletSmall}) {
            transform: translateX(35px);
          }
        }
      }
    `}
`;
export const Text = styled(Title)`
  color: ${(props) => props.theme.colors.darkYellow};
  transition: all 0.2s ease;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    font-size: 14px;
  }
`;
