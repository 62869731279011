import styled from "styled-components";

export const Image = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 472px;
  margin-top: 50px;

  img {
    object-fit: contain;
  }
`;
