import styled from "styled-components";

export const Image = styled.div`
  margin: 0 auto;
  width: 440px;
  height: 542px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
