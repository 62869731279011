import React from "react";
import * as SC from "./styles";
import { Button } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";

const AuthButtons: React.FC = () => {
  return (
    <SC.Wrap>
      <Button type="outer-link" path={_t("dashboard_link_sign_in")}>
        {_t("sign_in")}
      </Button>{" "}
      <Button type="outer-link" path={_t("dashboard_link_sign_up")} isBorder>
        {_t("sign_up")}
      </Button>
    </SC.Wrap>
  );
};

export default AuthButtons;
