import React from "react";
import { useParams } from "react-router-dom";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import { getLocale } from "system/helpers/localstorageHelper";
import { useApiQuery } from "common/hooks/useApiQuery";
import { API_TOURNAMENT } from "./constants";
import * as SC from "./styles";
import { AppTitle } from "../components";
import PrizesInfo from "./components/PrizesInfo/PrizesInfo";
import { ResultInfo } from "./components";

const TournamentPage = () => {
  const { id } = useParams();
  const locale = getLocale();
  const { data, isLoading }: any = useApiQuery(
    API_TOURNAMENT.key,
    API_TOURNAMENT.path,
    "get",
    {
      params: {
        language: locale,
        id,
      },
    }
  );
  return (
    <DefaultPage>
      <Container>
        <SC.Card>
          <SC.Image>
            <img
              src={`${data?.tournament.host}${data?.tournament?.image}`}
              alt=""
            />
          </SC.Image>
          <SC.Date>
            {data?.tournament.date_from_parsed} <span />{" "}
            {data?.tournament.date_to_parsed}
          </SC.Date>
          <AppTitle>{data?.tournament.title}</AppTitle>
          <SC.CardContent>
            <SC.Description
              dangerouslySetInnerHTML={{ __html: data?.tournament.content }}
            ></SC.Description>
            <SC.CardContentSide>
              {data && <PrizesInfo prizes={data?.prizes} />}
              {data && <ResultInfo result={data?.results} />}
            </SC.CardContentSide>
          </SC.CardContent>
        </SC.Card>
      </Container>
    </DefaultPage>
  );
};

export default TournamentPage;
