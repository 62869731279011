import styled from "styled-components";

export const Card = styled.div`
  padding-bottom: 100px;
`;
export const Date = styled.p`
  display: flex;
  align-items: center;
  color: #3ccc87;
  margin-bottom: 15px;

  span {
    width: 50px;
    margin: 0 15px;
    height: 1px;
    background: #3ccc87;
  }
`;
export const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 30px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    flex-direction: column-reverse;
  }
`;
export const CardContentSide = styled.div`
  width: 370px;
  margin-left: 30px;
  flex: none;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
`;
export const Image = styled.div`
  height: 360px;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 18px 18px 0 0;
  margin-bottom: 30px;
  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    height: 270px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const Description = styled.div`
  flex: 1;
  font-size: 24px;
  line-height: 1.25;
  word-break: break-all;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    font-size: 16px;
  }

  > * {
    margin: 15px 0;

    @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
      margin: 10px 0;
    }
  }

  ul {
    padding-left: 30px;
    margin: 30px 0;

    @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
      padding-left: 10px;
      margin: 15px 0;
    }

    li {
      position: relative;
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.primeColor};

        @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
          top: 5px;
        }
      }

      + li {
        margin-top: 25px;

        @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
          margin-top: 10px;
        }
      }
    }
  }
`;
