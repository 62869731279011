import React from "react";
import * as SC from "./styles";
import { AppText } from "pages/outer/components";

const DetailsBox = ({ title, tableInfo }) => {
  return (
    <SC.Card>
      <SC.Header>
        <AppText size={1}>{title}</AppText>
      </SC.Header>
      <SC.CardContent>
        <table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              {tableInfo.columns.map((item, idx) => (
                <td key={idx}>{item}</td>
              ))}
            </tr>
          </thead>
          <tbody>{tableInfo.content}</tbody>
        </table>
      </SC.CardContent>
    </SC.Card>
  );
};

export default DetailsBox;
