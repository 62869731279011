import React from "react";
import { NavMenu } from "layouts/outer/components/NavMenu";
import { _t } from "modules/Common/components/InjectIntlContext";
import { headerMenuList } from "./constants";
import * as SC from "./styles";

const HeaderMenu = () => {
  const translatedMenu = headerMenuList(_t);
  return (
    <SC.Menu>
      <NavMenu menuList={translatedMenu} />
    </SC.Menu>
  );
};

export default HeaderMenu;
