import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";
import { Text } from "pages/outer/components/AppText/styles";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 460px;
  padding: 0 35px 35px;
  background: #000;
  border-radius: 18px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);
  background: ${(props) => props.theme.colors.darkCard};
  transition: all 0.2s ease;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    height: 400px;
  }

  ${() => css`
    ${Text} {
      color: ${(props) => props.theme.colors.fontSecondaryColor};
    }

    ${Title} {
      color: ${(props) => props.theme.colors.gray};
      margin-bottom: 15px;
    }

    ${Text}, ${Title} {
      transition: color 0.2s ease;
    }
  `}
  &:hover {
    transform: translateY(-15px);
    box-shadow: 0px 30px 40px -20px rgba(0, 0, 0, 0.35);

    @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
      transform: translateY(0);
    }
    ${() => css`
      ${Text}, ${Title} {
        color: ${(props) => props.theme.colors.fontPrimeColor};
      }
    `}
  }
`;

export const Image = styled.div`
  margin-bottom: 20px;
  height: 259px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -10px;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    height: 180px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const ButtonWrap = styled.div`
  width: 100%;
  margin-top: auto;

  > * {
    width: 100%;
  }
`;
