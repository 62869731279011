import React from "react";
import * as SC from "./styles";
import { AppText, AppTitle } from "pages/outer/components/index";
import { Link } from "react-router-dom";
import { _t } from "modules/Common/components/InjectIntlContext";

const Card = ({ post }) => {
  const articleLink = `/article/${post.url}`;
  return (
    <SC.Card>
      <SC.Image>
        <img src={`${post.host}${post.options_parsed.image}`} alt="" />
        <AppTitle lvl={4} size={3}>
          <Link to={articleLink}>{post.caption}</Link>
        </AppTitle>
      </SC.Image>
      <SC.CardContent>
        <AppText secondary>{post.post_date}</AppText>
        <SC.CardDescription>
          <AppText>
            <span dangerouslySetInnerHTML={{ __html: post.short_text }}></span>
          </AppText>
        </SC.CardDescription>
        <SC.ReadMore>
          <Link to={articleLink}>{_t("read_more")}</Link>
        </SC.ReadMore>
      </SC.CardContent>
    </SC.Card>
  );
};

export default Card;
