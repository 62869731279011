import { card_1, card_2, card_3, card_4 } from "./images";
import { APP_ROUTES } from "system/constants/constantsUrl";

export const commissionsList = (t) => [
  {
    title: t("revshare_commission"),
    text: t("revshare_commission_desc"),
    buttonText: t("read_more"),
    path: APP_ROUTES.commissions.revshare,
    image: card_1,
  },
  {
    title: t("cpa_program"),
    text: t("cpa_program_desc"),
    buttonText: t("read_more"),
    image: card_2,
    path: APP_ROUTES.commissions.cpa,
  },
  {
    title: t("subaffiliate_program"),
    text: t("subaffiliate_program_desc"),
    buttonText: t("read_more"),
    image: card_3,
    path: APP_ROUTES.commissions.subaffiliate,
  },
  {
    title: t("hybrid_program"),
    image: card_4,
    path: APP_ROUTES.commissions.hybrid,
    buttonText: t("read_more"),
    text: t("hybrid_program_desc"),
  },
];
