import styled from "styled-components";
import { closeIcon } from "./images";

export const Button = styled.button`
  position: absolute;
  left: 20px;
  top: 15px;
  width: 35px;
  height: 35px;
  background: url(${closeIcon}) no-repeat center / contain;
  border: none;
  cursor: pointer;
`;
