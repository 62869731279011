import * as actionTypes from "./constants";

const initialState = {
  basicInfo: {
    isLoading: false,
    login: "",
    email: "",
    first_name: "",
    last_name: "",
  },
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case actionTypes.USER_BASIC_START:
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          isLoading: true,
        },
      };
    case actionTypes.USER_BASIC_SUCCESS:
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          ...action.payload.data,
          isLoading: false,
        },
      };
    case actionTypes.USER_BASIC_FAIL:
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          isLoading: false,
        },
      };
    case actionTypes.USER_BASIC_RESET:
      return initialState;
    case actionTypes.GENERATE_CSV_KEY_SUCCESS:
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          ...action.payload.data.settings,
        },
      };
    default:
      return state;
  }
}
