import React from "react";
import { aboutImage } from "../../images";
import * as SC from "./styles";

const Image = () => {
  return (
    <SC.Image>
      <img src={aboutImage} alt="" />
    </SC.Image>
  );
};

export default Image;
