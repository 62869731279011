import moment from "moment";
import {
  ADDITIONAL_CURRENCY_SYMBOL,
  ADDITIONAL_CURRENCY_SYMBOL_DATE,
} from "../../system/config";
import { getCurrencySymbol } from "./getCurrencySymbol";
import config from "../../system/config";

export const isAdditionalSymbolOrMixed = (date) => {
  return moment(date).isAfter(ADDITIONAL_CURRENCY_SYMBOL_DATE)
    ? getCurrencySymbol(date)
    : `${config.projectCurrency} / ${ADDITIONAL_CURRENCY_SYMBOL}`;
};
