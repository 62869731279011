import React from "react";
import { DefaultPage } from "layouts/outer/DefaultPage";
import {
  MainSlider,
  MainMapCards,
  OurBenefits,
  HowItWorks,
  Commissions,
  AboutUs,
} from "pages/outer/sections";

const Home: React.FC = () => {
  return (
    <DefaultPage hasBackground={false}>
      <MainSlider />
      <MainMapCards />
      <OurBenefits />
      <HowItWorks />
      <Commissions />
      <AboutUs />
    </DefaultPage>
  );
};

export default Home;
