import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.mediaQueries.HD}) {
    max-width: 100%;
    padding: 0 15px;
  }
`;
