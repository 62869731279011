import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
  padding-bottom: 60px;
`;
export const GridItem = styled.div`
  width: 50%;
  padding: 0 14px;
  padding-bottom: 28px;
  height: 100%;

  @media (max-width: ${(props) => props.theme.mediaQueries.tabletSmall}) {
    width: 100%;
  }
`;
