import { createGlobalStyle } from "styled-components";
import {
  InterRegularWoff,
  InterRegularWoff2,
  InterBoldWoff,
  InterBoldWof2,
} from "assets/fonts";

export const GlobalFontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter-Regular';
    src: url(${InterRegularWoff2}) format('woff2'),
    url(${InterRegularWoff}) format('woff');
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Inter-Bold';
    src: url(${InterBoldWof2}) format('woff2'),
    url(${InterBoldWoff}) format('woff');
    font-style: normal;
    font-display: fallback;
  }
`;
