import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
`;
export const GridItem = styled.div`
  padding: 0 14px 28px;
  width: calc(100% / 3);

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    width: 100%;
  }
`;
