import React from "react";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import { AppTitle, NewsGrid } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";

const News = () => {
  return (
    <DefaultPage>
      <Container>
        <AppTitle pageTitle size={1} lvl={1}>
          {_t("news")}
        </AppTitle>
        <NewsGrid />
      </Container>
    </DefaultPage>
  );
};

export default News;
