import styled, { css } from "styled-components";

export const Section = styled.section`
  padding: 75px 0;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding: 35px 0;
  }
  ${(props) =>
    props.$additionClass === "our-benefits" &&
    css`
      padding-bottom: 0;
      position: relative;
      z-index: 2;
    `}
`;

export const TitleWrap = styled.div`
  margin-bottom: 45px;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    margin-bottom: 25px;
  }
`;
