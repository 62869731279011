import styled, { css } from "styled-components";

export const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  min-width: 115px;
  padding: 10px 35px;
  line-height: 25px;
  border-radius: 100px;
  color: ${(props) => props.theme.colors.fontContrast};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 16px;
  border: none;
  transition: all 0.3s ease;
  background: ${(props) => props.theme.colors.primeColor};
  overflow-x: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background: ${(props) => props.theme.colors.primeGradient};
    transition: all 0.3s ease;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    color: ${(props) => props.theme.colors.fontContrast};
    &::before {
      opacity: 0;
    }
  }

  ${(props) =>
    props.$isBorder &&
    css`
      padding: 8px 35px;
      color: ${(props) => props.theme.colors.primeColor};
      background: none;
      border: 2px solid ${(props) => props.theme.colors.primeColor};
      &::before {
        display: none;
      }
      &:hover {
        background: ${(props) => props.theme.colors.primeColor};
        color: ${(props) => props.theme.colors.fontContrast};
      }
    `}
`;
