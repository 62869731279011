import React from "react";
import * as SC from "./styles";
import { faqNav } from "./constants";
import { _t } from "modules/Common/components/InjectIntlContext";

const Nav = ({ filter, setFilter }) => {
  const translatedNav = faqNav(_t);
  return (
    <SC.Nav>
      {translatedNav.map((item, idx) => (
        <SC.NavItem key={idx}>
          <SC.NavButton
            $active={filter === item.value}
            onClick={() => setFilter(item.value)}
          >
            {item.title}
          </SC.NavButton>
        </SC.NavItem>
      ))}
    </SC.Nav>
  );
};

export default Nav;
