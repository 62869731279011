import React from "react";
import { useDispatch } from "react-redux";
import { Language } from "pages/outer/components/LangDropdown/components/index";
import { languagesFlags } from "../../constants";
import { updateLocale } from "modules/Locales/actions";
import * as SC from "./styles";
const Dropdown = ({ languages, locale }) => {
  const dispatch = useDispatch();
  const LanguageItem = (item) => {
    if (item.code === locale) return null;

    return (
      <Language
        cb={() => dispatch(updateLocale(item.code))}
        // flag={languagesFlags[item.code]}
        title={item.name}
        key={item.id}
      />
    );
  };
  return (
    <SC.DropdownWrap>
      <SC.DropdownContent>
        {Object.values(languages).map(LanguageItem)}
      </SC.DropdownContent>
    </SC.DropdownWrap>
  );
};

export default Dropdown;
