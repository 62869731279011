import { image_1, image_2, image_3 } from "./images";

export const benefitsList = (t) => [
  {
    image: image_1,
    title: t("benefit_title_1"),
    text: t("benefit_1"),
  },
  {
    image: image_2,
    title: t("benefit_title_2"),
    text: t("benefit_2"),
  },
  {
    image: image_3,
    title: t("benefit_title_3"),
    text: t("benefit_3"),
  },
];
