import styled from "styled-components";
import { Form } from "antd";

export const FormItem = styled(Form.Item)`
  position: relative;
  margin-bottom: 25px;

  .ant-form-item-control-input {
    min-height: initial;
  }

  .ant-form-item-explain {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    font-size: 11px;
    color: #ff4d4f;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
  }
  .ant-checkbox {
    top: 2px;

    .ant-checkbox-inner {
      background: ${(props) => props.theme.colors.dark};
      border-color: ${(props) => props.theme.colors.fontSecondaryColor};
    }

    &.ant-checkbox-checked {
      &::after {
        border-color: ${(props) => props.theme.colors.primeColor} !important;
      }
      .ant-checkbox-inner {
        border-color: ${(props) => props.theme.colors.primeColor} !important;
      }
    }
  }
`;
